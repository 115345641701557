<template>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          {{ $t("views.sales.weekly.title") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              class="pa-3"
              cols="12"
              md="3">
              <menu-date-picker
                v-model="filters.date"
                :date-picker-options="{
                  allowedDates: this.allowedDates
                }"
                :input-options="{
                  'prepend-inner-icon': 'mdi-calendar',
                  'dense': true,
                  'hide-details': true,
                  'outlined': true,
                  'label': $t('fields.date')
                }" />
            </v-col>
            <v-col
              class="pa-3"
              cols="12"
              md="3">
              <v-autocomplete
                v-model="filters.zoneIds"
                :items="zones"
                item-text="label"
                item-value="value"
                :label="$t('fields.zones')"
                dense
                multiple
                outlined />
            </v-col>
            <v-col>
              <v-btn
                color="success"
                @click="getSales">
                {{ $t("actions.filter") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <btn-group-text
              :title="$t('views.sales.weekly.filter')"
              :selected.sync="selectedSalesFilter"
              :options="filterOptions"
              name="winning_ticket_control"
              color="info"
              attribute="name"
              translation-base="views.sales.weekly.filterOptions"
              mandatory
              @change="filterChanged" />
          </v-row>
          <br>
          <data-table
            class="stripped"
            :loading="loading"
            :headers="tableColumns"
            dense
            :items="effectiveSales">
            <template #item.initialBalance="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.totalSold="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.totalPrizes="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.totalJackpotPrizes="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.jackpotCollected="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.mondayNetSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.tuesdayNetSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.wednesdayNetSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.thursdayNetSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.fridayNetSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.saturdayNetSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.sundayNetSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.netSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.jackpot="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.upToDateBalance="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.purse="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.cashOuts="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.commission="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.totalEarnings="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <!-- Totals -->
            <template #body.append>
              <tr>
                <th class="text-center">Total</th>
                <th class="text-center">-</th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("initialBalance")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("totalSold")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("totalPrizes")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("jackpotCollected")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("mondayNetSale")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("tuesdayNetSale")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("wednesdayNetSale")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("thursdayNetSale")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("fridayNetSale")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("saturdayNetSale")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("sundayNetSale")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("netSale")) }}
                </th>
                <th v-if="showJackpot" class="text-center">
                  {{ $formatter.asCurrency(sumField("totalJackpotPrizes")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("cutoffBalance")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("jackpot")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("purse")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("cashOuts")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("commission")) }}
                </th>
                <th class="text-center">
                  {{ $formatter.asCurrency(sumField("totalEarnings")) }}
                </th>
              </tr>
            </template>
          </data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getParams, getDailySales, getWeeklySales } from "@/api/sale";
import moment from "moment";
import { i18n } from "@/i18n";
import MenuDatePicker from "@/components/inputs/MenuDatePicker.vue";
import BtnGroupText from "../../components/inputs/BtnGroupText";
import DataTable from "@/components/DataTable.vue";

const WITHOUT_SALES = 0, WITH_SALES = 1, ALL = 2,
  filterOptions = [
    { id: WITHOUT_SALES, name: "withoutSales" },
    { id: WITH_SALES, name: "withSales" },
    { id: ALL, name: "all" },
  ];

function data() {
  return {
    sales: [],
    zones: [],
    showJackpot: false,
    filters: {
      date: moment().
      format("YYYY-MM-DD"),
      zoneIds: [],
    },
    loading: false,
    selectedSalesFilter: WITH_SALES,
    filterOptions,
    WITH_SALES,
    WITHOUT_SALES,
    ALL,
  };
}

export default {
  name: "Weekly",
  components: {
    DataTable,
    MenuDatePicker,
    BtnGroupText,
  },
  data,
  computed: {
    tableColumns() {
      return [
        {
          value: "reference",
          text: i18n.t("views.sales.weekly.tableColumns.reference"),
          align: "center",
          sortable: true,
        },
        {
          value: "bettingPoolCode",
          text: i18n.t("views.sales.weekly.tableColumns.code"),
          align: "center",
          sortable: true,
        },
        {
          value: "initialBalance",
          text: i18n.t("views.sales.weekly.tableColumns.initialBalance"),
          align: "center",
          sortable: true,
        },
        {
          value: "totalSold",
          text: i18n.t("views.sales.weekly.tableColumns.sale"),
          align: "center",
          sortable: true,
        },
        {
          value: "totalPrizes",
          text: i18n.t("views.sales.weekly.tableColumns.prizes"),
          align: "center",
          sortable: true,
        },
        {
          value: "jackpotCollected",
          text: i18n.t("views.sales.weekly.tableColumns.jackpotCollected"),
          align: "center",
          sortable: true,
        },
        {
          value: "mondayNetSale",
          text: i18n.t("views.sales.weekly.tableColumns.monday"),
          align: "center",
          sortable: true,
        },
        {
          value: "tuesdayNetSale",
          text: i18n.t("views.sales.weekly.tableColumns.tuesday"),
          align: "center",
          sortable: true,
        },
        {
          value: "wednesdayNetSale",
          text: i18n.t("views.sales.weekly.tableColumns.wednesday"),
          align: "center",
          sortable: true,
        },
        {
          value: "thursdayNetSale",
          text: i18n.t("views.sales.weekly.tableColumns.thursday"),
          align: "center",
          sortable: true,
        },
        {
          value: "fridayNetSale",
          text: i18n.t("views.sales.weekly.tableColumns.friday"),
          align: "center",
          sortable: true,
        },
        {
          value: "saturdayNetSale",
          text: i18n.t("views.sales.weekly.tableColumns.saturday"),
          align: "center",
          sortable: true,
        },
        {
          value: "sundayNetSale",
          text: i18n.t("views.sales.weekly.tableColumns.sunday"),
          align: "center",
          sortable: true,
        },
        {
          value: "netSale",
          text: i18n.t("views.sales.weekly.tableColumns.netSale"),
          align: "center",
          sortable: true,
        },
        {
          value: "totalJackpotPrizes",
          text: i18n.t("views.sales.weekly.tableColumns.totalJackpotPrizes"),
          align: "center",
          sortable: true,
        },
        {
          value: "cutoffBalance",
          text: i18n.t("views.sales.weekly.tableColumns.cutoffBalance"),
          align: "center",
          sortable: true,
        },
        {
          value: "jackpot",
          text: i18n.t("views.sales.weekly.tableColumns.jackpot"),
          align: "center",
          sortable: true,
        },
        {
          value: "purse",
          text: i18n.t("views.sales.weekly.tableColumns.purse"),
          align: "center",
          sortable: true,
        },
        {
          value: "cashOuts",
          text: i18n.t("views.sales.weekly.tableColumns.cashOuts"),
          align: "center",
          sortable: true,
        },
        {
          value: "commission",
          text: i18n.t("views.sales.weekly.tableColumns.commission"),
          align: "center",
          sortable: true,
        },
        {
          value: "totalEarnings",
          text: i18n.t("views.sales.weekly.tableColumns.netEarning"),
          align: "center",
          sortable: true,
        },
      ];
    },
    effectiveSales() {
      switch (this.selectedSalesFilter) {
        case WITHOUT_SALES:
          return this.sales.filter(weeklySaleEntry => parseFloat(weeklySaleEntry["totalSold"]) === parseFloat(0));
        case WITH_SALES:
          return this.sales.filter(weeklySaleEntry => parseFloat(weeklySaleEntry["totalSold"]) !== parseFloat(0));
        case ALL:
          return this.sales;
        default:
          return [];
      }
    },
  },
  created() {
    getParams().
    then(response => {
      this.showJackpot = response.data.showJackpot;
      this.zones = response.data.zones;
      this.filters.zoneIds = this.zones.map(zone => zone.value);
    });
  },
  methods: {
    sumField(key) {
      return this.effectiveSales.reduce((a, b) => parseFloat(a) + parseFloat((b[key] || 0)), 0);
    },
    allowedDates(value) {
      const momentObj = moment(value, "YYYY-MM-DD").
      startOf("day");
      const tomorrowDate = moment().
      startOf("day").
      add(1, "days");
      
      return momentObj.isBefore(tomorrowDate);
    },
    getSales() {
      getWeeklySales(this.filters).
      then(response => {
        this.sales = response.data.sales;
      });
    },
    filterChanged(value) {
      this.selectedSalesFilter = value;
    },
  },
};
</script>

<style scoped>
</style>
